body{
  margin:0px;
  padding:0px;
  background-color:#ececee;
  font-family: 'Roboto', sans-serif;
  font-size:16px;
}
.displaynone{
  display: none;
}
.task_status{
 width: 95px;height: 23px;text-align: center;color: white;line-height: 24px;border-radius: 3px;
  font-size: 13px;font-family: 'Roboto', sans-serif;
}
.mobile-ham-menu{
  display: none;
}
.red{
  background-color: #FF0000;
}
.yellow{
 background-color: #F8AC59;
}
.main_container{
  margin:0px auto;
  padding:0px;  
}

.floatleft{
  float: left;
}

.floatright{
  float: right;
}

.top-header{
  background-color:#293947;
  width:100%;
  height:96px;
  position:fixed;
  z-index:5;
}

.login-header{
  background-color:#2f4050;
  width:100%;
  height:96px;
  z-index:5;
}

.header-center{
  width:96%;
  margin:0px auto;
  padding-top:25px;
}

.logo{
  width:25%;
  float:left;
}

.profile-area {
  float: right;
  text-align: center;
  margin-top: -15px;
}
.profile-username{
  color: #FFFFFF;
  text-decoration: none;
  font-size: 18px;
}
.logout-link{
  color: #FFFFFF;
  text-decoration: none;
  font-size: 12px;
}
.top-search{
  width:60%;
  float:left; 
}

.login-area {
  width: 500px;
  margin:0px auto;
  text-align: center;
}
.login-heading{
  background-color: #0E3F68;
padding: 26px;
color: #FFF;
text-align: left;
font-weight: bold;
}
.login-input-area{
  background-color: #FFFFFF;
  padding: 20px;
  height: 250px;
}
.forgot-input-area{
  background-color: #FFFFFF;
  padding: 20px;
  height: 150px;
  padding-bottom: 40px;
}
.login-input-section{
  background-color: #F5F5F5;
  padding: 10px;
  border-radius: 10px;
}
.login-input {
  margin-top: 30px;
  text-align: left;
  margin-left: 10px;
}
.login-input-section input[type="text"], .login-input-section input[type="password"]{
  outline: none;
  width: 93%;
padding: 10px;
border: none;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: medium;
background-color: #EEE;
border-bottom: 1px solid #CCC;
font-weight: bold;
color: #555555;
}
.taskcount{
  float: left;
  width: 24%;
}
.login-submit-button{
  background-color: #038FDA;
padding: 10px;
width: 123px;
border: none;
border-radius: 8px;
font-size: 12px;
color: #FFF;
cursor: pointer;
}
.forgot-password{
  color: #000;
}
.top-search select{
  width: 100%;
height: 48px;
border: 5px solid #2374b7;
border-radius: 50px;
background-color: white;
font-size: 18px;
color: #000f1c;
padding-left: 20px;
font-family: 'Roboto', sans-serif;
}
.top-search select option{
  color: #000;
}
.clear{
  clear:both; 
}

.body-container{
  width:100%;
  margin:0px auto;  
}

.left-menu{
  width:96px;
  background-color:#293947;
  padding-top:110px;
  position:absolute;
  top: 0;
  left: 0;
  overflow-x: hidden;
  z-index:4;
  text-decoration: none;
}

.sidebar-links a {
  color: #C4C4C4;
  font-size: 15px;
  text-decoration: none;
}
.sidebar-menu-title{
  text-decoration: none;
}

.left-menu-item{
  padding-top:10px;
  width:100%;
  height:60px;
  text-align:center;
}

.left-menu-item a{
  display:block;
  text-decoration: none;
}

.left-menu-item:hover, .left-menu-item-active, .active .left-menu-item {
  background-color:#212e39;
}

.right-content{
  width:100%;
  height:100%;
  float:left;
  border:0px solid red;
}

.content-top{
  background-color:#0496e3;
  height:96px;
  width:100%;
  position:relative;
  margin-top:96px;
  z-index:2;
}

.content-top-left{
  float:left;
  width:40%;
  margin-left:120px;
  border:0px solid #F00;
}

.content-top-right{
  float:right;
  width:50%;
  border:0px solid #F00;
}

.content-top-left h1{
  font-family: 'Roboto', sans-serif;
  padding:0px;
  font-size:20px;
  color:#FFF;
  font-weight:700;
  line-height:50px;
}

.top-btn a{
  padding:15px 30px;
  background-color:#2f4050;
  border-radius:10px;
  font-family: 'Roboto', sans-serif;
  font-weight:700;
  font-size:25px;
  color:#FFF;
  text-decoration:none;
  float:right;
  display:block;
  margin:20px 20px 0px 20px;
}

.email-btn a{
  padding:10px 20px;
  background-color:#2f4050;
  border-radius:10px;
  font-family: 'Roboto', sans-serif;
  font-weight:700;
  font-size:15px;
  color:#FFF;
  text-decoration:none;
  float:right;
  display:block;
  margin:20px;
  cursor: pointer;
}

.servicehefte-btn{
  padding:10px 15px;
  background-color:#2f4050;
  border-radius:10px;
  font-family: 'Roboto', sans-serif;
  font-weight:400;
  font-size:16px;
  color:#FFF;
  cursor: pointer;
  text-decoration:none;
  display:block;
  margin:20px 0px 0px 20px;
}

.servicehefte-btn[disabled]{
  background-color: #000 !important;
  opacity: 0.5 !important;
  cursor: wait;
}

.right-main-content{
  width:92%;
  margin:0px auto;
  margin-top:30px;
  border:0px solid green;
  margin-left:120px;
}

.help-area{
  margin:0px auto;
  padding:10px 30px;
  background-color:#293947;
}

.help-area h1, .report-help-area h1, .fdv-help-area h1{
  font-family: 'Roboto', sans-serif;
  font-weight:700;
  font-size:25px;
  color:#FFF;
}


.help-area p{
  font-family: 'Roboto', sans-serif;
  font-size:16px;
  color:#FFF;
}
.welcome-text-collapse{
  cursor: pointer;
}
.report-help-area{
  margin:0px auto;
  padding:10px 30px;
  background-color:#9EA4B4;
} 


.fdv-help-area{
  margin:0px auto;
  padding:10px 30px;
  background-color:#0496e3;
} 

.report-radio-area {
  margin:0px auto;
  padding:30px 50px;
  background-color:#FFFFFF;
}
.folder-list{
 float: left;
padding: 11px 0px;
width: 100px;
margin-right: 15px;
}

.folder-list-selected {
  background-color: #D2CFCF;
  border: 0px solid #a0a1a2;
border-radius: 4px;
}

.folder-icon, .folder-name{
  text-align: center;
  width: 100px;
  font-weight: bold;
  white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
cursor: pointer;
}

.report-radio-area .radio {
  float: left; 
  margin-right: 10px;
}

.report_button {
  width: 219px;
  padding: 11px;
  color: #FFF;
  background-color: #2F4050;
  border: none;
  border-radius: 6px;
  font-size: 15px;
  font-weight: bold;

}
.savenclose:disabled{
background-color: #74787b;
}
.report_button:disabled{
  background-color: #868E95;
  opacity: 0.8;
  cursor: wait;
}
.swtich-text{
padding: 7px;
color: #2b2b2b;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #0496e3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.divider{
  width:100%;
  height:30px;  
}

.help-logo{
  padding:10px;
}

.data-box-01{
  width:70%;
  background-color:#FFF;
  float:left;
}

.data-box-top{
  background-color:#0d3f68;
  width:100%;
  height:90px;
}
.building-top{
  float: left;
}
.building-top-select{
  float: right;
  width: 25%;
  line-height: 4;
  text-align: right;
  padding: 10px;
}
.building-top-select select {
  width: 100%;
height: 48px;
border: 5px solid #2374b7;
border-radius: 50px;
background-color: white;
font-size: 18px;
color: #000f1c;
padding-left: 20px;
font-family: 'Roboto', sans-serif;
}
.building-top-select select option {
  color: #000000;
}

.data-box-top h1, .servicehefte-box-top h1{
  font-family: 'Roboto', sans-serif;
  font-weight:700;
  font-size:35px;
  line-height:90px;
  color:#FFF;
  margin:0px;
  padding-left:20px;
}
.servicehefte-box-top{
 background-color:#9DA3B3;
  width:100%;
  height:90px; 
}

.hms_docs{
background-repeat: no-repeat;
width: 70px;
font-size: 11px;
color: #fff;
line-height: 30px;
float: left;
background-size: 55px 28px;
background-color: #0496E3;
margin: 1px;
text-align: center;
border: 1px solid #1575a8;
}

.servicehefte_docs{
background-repeat: no-repeat;
width: 40px;
font-size: 11px;
color: #fff;
line-height: 30px;
float: left;
background-size: 55px 28px;
background-color: #0496E3;
margin: 1px;
text-align: center;
border: 1px solid #1575a8;
}

.data-box-content{
  width:95%;
  margin:0px auto;
  padding:20px 0px;
  border:0px solid #9F0;
  overflow: auto;
}

.db-content{
  width:100%;
  margin:0px auto;
  padding:20px 0px;
  border:0px solid #9F0;
}


.db-content-top{
  width:100%;
  margin:0px auto;  
}

.data-box-table h3{
  font-family: 'Roboto', sans-serif;
  font-weight:700;
  font-size:16px;
  color:#505153;
  margin:0px;
  padding:0px;
}

.data-box-table h2{
  font-family: 'Roboto', sans-serif;
  font-weight:400;
  font-size:24px;
  color:#505153;
  margin:0px;
  padding:0px;
}

.db-content-box{
  background-color:#FFFFFF;
  padding:20px 10px;
}

.db-content-box h4{
  font-family: 'Roboto', sans-serif;
  font-weight:700;
  font-size:18px;
  color:#666666;
  margin:0px;
  padding:0px;
}

.db-content-box p{
  font-family: 'Roboto', sans-serif;
  font-size:16px;
  line-height:24px;
  color:#666666;
}

.data-box-02-pending{
  width:100%;
  height:auto;
  background-color:#ED5564;
  float:left;
  margin-left:20px;
  padding:100px 20px;
}
.data-box-02-pending .pending-count, .data-box-02-progress .pending-count{
font-size:130px;
font-weight: bold;
font-family: 'Roboto', sans-serif;
 color:#FFF;
}
.data-box-02-congrat{
  width:100%;
  height:auto;
  background-color:#5cb85c;
  float:left;
  margin-left:20px;
  padding:100px 20px;

}

.data-box-02-progress{
  width:100%;
  height:auto;
  background-color:#F8AC59;
  float:left;
  margin-left:20px;
  padding:100px 20px;
  
}

.data-box-02-congrat img, .data-box-02-pending img, .data-box-02-progress img{
  padding-bottom:0px;
}

.data-box-02-congrat h1, .data-box-02-pending h1, .data-box-02-progress h1{
  font-family: 'Roboto', sans-serif;
  font-weight:700;
  font-size:35px;
  color:#FFF;
  margin:0px;
  padding:0px;
}

.data-box-02-congrat h2, .data-box-02-pending h2, .data-box-02-progress h2{
  font-family: 'Roboto', sans-serif;
  font-weight:400;
  font-size:18px;
  color:#FFF;
  margin:0px;
  padding:0px;
}

.data-box-03{
  width:100%;
  background-color:#FFFFFF;
  float:left;
  
}

.data-box-04{
  width:100%;
  background-color:#FFFFFF;
  float:left;
}

.db-table th{
  font-weight:700;
  padding:5px 0px;
}

.db-table-tr{
  border-radius:10px;
}


.db-table-tr td{
  padding:10px;
}

.activity-bar{
  padding:10px 0px;
  margin-top:5px;
  border-bottom:1px solid #666;
}

.activity-bar-bottom{
  padding:5px 0px;
  background-color:#293947;
  border-radius:10px;
  margin-top:10px;
}



.activity-bar-bottom a{
  font-family: 'Roboto', sans-serif;
  font-weight:700;
  font-size:18px;
  color:#FFFFFF;
  text-decoration:none;
}


.activity-bar h4{
  font-family: 'Roboto', sans-serif;
  font-weight:700;
  font-size:14px;
  color:#666666;
  margin:0px;
  padding:0px;
}

.activity-bar h3{
  font-family: 'Roboto', sans-serif;
  font-weight:400;
  font-size:18px;
  color:#666666;
  margin:0px;
  padding:0px;
}

.boxes-left{
  float:left;
  width:73%;
  border:0px solid red;
  display: flex;
  flex-wrap: wrap;
}

.boxes-right{
  float:right;
  width:25%;
  border:0px solid blue;
}

.error-message{
  color: red;
   font-family: 'Roboto', sans-serif;
}

.savenclose {
  padding: 10px 20px;
background-color: #2f4050;
border-radius: 0px;
border: none;
font-family: 'Roboto', sans-serif;
font-weight: 700;
font-size: 15px;
color: #FFF;
text-decoration: none;
display: block;
margin: 0px auto;
cursor: pointer;
}
.react-datepicker-wrapper input {
  padding: 10px;
  font-size: 15px;
  font-weight: bold;
}
.profile-input {
  width: 350px;
padding: 10px;
border-radius: 39px;
border: 3px solid #2374b7;
outline: none;
 font-size: 15px;
  font-weight: bold;
}
.profile-updated{
    background-color: #d2f2c94f;
    width: 300px;
    padding: 10px;
    border: 1px solid #97c669;
    font-size: 14px;
}
.sign-upload-error{
    background-color: #ff7c7c4f;
width: 300px;
padding: 10px;
border: 1px solid #ff6a6a;
font-size: 14px;
}
.profile_button {
  width: 219px;
  padding: 11px;
  color: #FFF;
  background-color: #2F4050;
  border: none;
  border-radius: 6px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;

}

.txtarea{
  width: 300px;
padding: 10px;
font-family: 'Roboto', sans-serif;
}
.inpfile{
  width: 300px;
border: 1px solid #8c8c8c;
padding: 10px;
}
.report_list{
  float: left;
  width: 150px;
}

.previewSignature{
  width: 150px;
box-shadow: 0px 0px 6px #000;
border: 1px solid #979797;
padding: 3px;
}

.sample-pdfs .pdf{
float: left;
width: 185px;
text-align: center;
border: 0px solid #AAA;
margin-right: 10px;
height: 100px;
padding: 10px;
}
.sample-pdfs .pdf a {
  color: #0496E3;
  text-decoration: none;
}
/**********REPORT CSS*************/
.report_page{
  width:1080px;
  height:1527px;
  margin:0px auto;
  border:1px solid #CCC;
}

.cover{
  background-image:url(http://localhost:3001/img/cover_bg.jpg);
  background-repeat:no-repeat;
  background-position:top center; 
}

.report_logo{
  padding-top:40px;
  padding-right:40px;
}

.top_text{
  padding-left:40px;
}

.top_text h3{
  padding:0px;
  margin:0px;
  font-family: 'Roboto', sans-serif;
  font-size:28px;
  font-weight:700;
}
.top_text p{
  padding:0px;
  margin:0px;
  font-family: 'Roboto', sans-serif;
  font-size:25px;
}

.main_image{
  width:925px;
  height:740px;
  margin:0px auto;
  margin-top:90px;
}


.main_image img {
  -webkit-border-top-right-radius: 100px;
  -webkit-border-bottom-left-radius: 100px;
  -moz-border-radius-topright: 100px;
  -moz-border-radius-bottomleft: 100px;
  border-top-right-radius: 100px;
  border-bottom-left-radius: 100px;
}

.report_heading{
  margin-top:80px;
  padding-left:40px;
}

.report_heading h1{
  padding:0px;
  margin:0px;
  font-family: 'Roboto', sans-serif;
  font-size:55px;
  font-weight:700;
}
.report_heading p{
  padding:0px;
  margin:0px;
  font-family: 'Roboto', sans-serif;
  font-size:25px;
}


.index_page{
  background-image:url(http://localhost:3001/img/index_bg.jpg);
  background-repeat:no-repeat;
  background-position:top center;
  margin-top:20px;
}


.inner_page{
  background-image:url(http://localhost:3001/img/inner_page.jpg);
  background-repeat:no-repeat;
  background-position:top center;
  margin-top:20px;
}

.page_header{
  width:950px;
  height:70px;
  background-color:#f1f1f2;
  margin:0px auto;
  margin-top:25px;  
}

.head_logo{
  float:left; 
}

.head_pageNum{
  float:right;
  width:64px;
  height:64px;
  background-color:#0495e2;
  font-family: 'Roboto', sans-serif;
  font-size:29px;
  line-height:64px;
  font-weight:700;
  text-align:center;
  color:#FFF;
  margin-top:3px;
  margin-right:3px;
}

.clear{
  clear:both; 
}

.report_content{
  width:600px;
  border:0px solid #00F;
  margin:0px auto;
}

.report_content h1{
  margin:0px;
  padding:0px;
  font-family: 'Roboto', sans-serif;
  font-size:73px;
  font-weight:700;
  color:#0495e2;
  margin-top:50px;
}

.index_items{
  margin:0px auto;
  padding-top:50px;
}

.items{
  margin:10px 0px;
}

.items_submenu{
  margin:10px 0px;
}

.topic{
  float:left;
  width:78%;
  height:45px;
  background-color:#d8d8d8;
  font-family: 'Roboto', sans-serif;
  font-size:20px;
  line-height:45px;
  color:#404041;
  padding-left:20px;
}

.pagenumber{
  float:right;
  width:95px;
  height:45px;
  background-color:#0495e2;
  font-family: 'Roboto', sans-serif;
  font-size:29px;
  line-height:45px;
  font-weight:700;
  text-align:center;
  color:#FFF;
}

.topic_submenu{
  float:left;
  width:73%;
  height:45px;
  background-color:#efefef;
  font-family: 'Roboto', sans-serif;
  font-size:20px;
  line-height:45px;
  color:#404041;
  padding-left:20px;
  margin-left:30px;
}


.report_inner_content{
  width:950px;
  height:1360px;
  border:0px solid #00F;
  margin:0px auto;
  margin-top:20px;
  padding-bottom:20px;
  overflow:hidden;
}

.report_inner_content h1{
  margin:0px;
  padding:0px;
  font-family: 'Roboto', sans-serif;
  font-size:36px;
  font-weight:700;
  color:#0495e2;
}

.report_inner_content p{
  margin:0px;
  padding:0px;
  font-family: 'Roboto', sans-serif;
  font-size:16px;
  line-height:20px;
  color:#404041;
  padding-top:10px;
}

.footer_text{
  width:950px;
  margin:0px auto;
  font-family: 'Roboto', sans-serif;
  font-size:14px;
  color:#404041;
}

.report_table{
  margin-top:20px;
}


.report_table td{
  padding:10px;
}

.report_table h3{
  padding:0px;
  margin:0px;
}

.report_table th{
  padding:10px;
  background-color:#2f4050;
  color:#FFF;
  font-size:25px;
  font-weight:700;
  text-align:left;
}
/**********REPORT CSS*************/